import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'MENU',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/admin/dashboard'
  },
  {
    label: 'Warandés',
    isTitle: true
  },
  {
    label: 'Warandés',
    icon: 'shopping-bag',
    link: '/admin/boutiques'
  },
  /* {
    label: 'Warandés Prospects',
    icon: 'shopping-bag',
    link: '/admin/boutiques-prospects'
  }, */
  {
    label: 'Naagaga',
    isTitle: true
  },
  {
    label: 'Produits',
    icon: 'package',
    link: '/admin/produits'
  },
  {
    label: 'Catégories',
    icon: 'layers',
    link: '/admin/categories'
  },
  {
    label: 'Commandes',
    icon: 'shopping-cart',
    link: '/admin/commandes'
  },
  {
    label: 'Paiements',
    icon: 'credit-card',
    link: '/admin/paiements'
  },
  /* {
    label: 'Clients',
    icon: 'users',
    link: '/admin/clients'
  }, */
  {
    label: 'Ma warandé',
    isTitle: true
  },
  {
    label: 'Mes produits',
    icon: 'package',
    link: '/admin/mes-produits'
  },
  {
    label: 'Mes Catégories',
    icon: 'layers',
    link: '/admin/mes-categories'
  },
  {
    label: 'Mes commandes',
    icon: 'shopping-cart',
    link: '/admin/mes-commandes'
  },
  {
    label: 'Mes paiements',
    icon: 'credit-card',
    link: '/admin/mes-paiements'
  },
  {
    label: 'Commentaires',
    icon: 'feather',
    link: '/admin/commentaires'
  },
  {
    label: 'Mes négociations',
    icon: 'dollar-sign',
    link: '/admin/mes-negociations'
  },
  /* {
    label: 'Mes clients',
    icon: 'users',
    link: '/admin/mes-clients'
  }, */
  {
    label: 'Codes promo',
    icon: 'gift',
    link: '/admin/promos'
  },
  {
    label: 'Attributs de produits',
    icon: 'figma',
    link: '/admin/attributes'
  },
  /* {
    label: 'Modes de livraison',
    icon: 'truck',
    link: '/admin/mode-livraison'
  }, */
  {
    label: 'CONFIGURATION',
    isTitle: true
  },
 
  /* {
    label: 'Modes de paiement',
    icon: 'credit-card',
    link: '/admin/mode-paiement'
  }, */
  {
    label: 'Home Slides',
    icon:  'image',
    link:  '/admin/home-slide'
   },
  {
    label: 'Bout./Cat. favories',
    icon:  'shopping-bag',
    link:  '/admin/boutiques-favories'
  },
  {
    label: 'Pages',
    icon:  'file',
    link:  '/admin/pages'
  },
  {
    label: 'Newsletters',
    icon:  'mail',
    link:  '/admin/newsletter'
  },
  {
    label: 'Plateforme Infos',
    icon:  'settings',
    link:  '/admin/platform-info'
  },
  {
    label: 'Settings',
    icon:  'settings',
    link:  '/admin/configuration'
  },
  {
    label: 'Formulaire de contact',
    icon:  'mail',
    link:  '/admin/contact-formulaire'
  },


  /* {
    label: 'Pages',
    icon: 'settings', 
    
    subItems: [
      {
        label: 'Home Slider',
        icon: 'image',
        link: '/admin/parametrage/home-slider',
      }
    ]
  } */
  /* ,
  {
    label: 'Catégories favories',
    icon:  'layers',
    link:  '/admin/boutiques-favories'
  }, */
   

  
  
  /* {
    label: 'Template',
    icon: 'settings',
    subItems: [
      {
        label: 'Home Slider',
        icon: 'image',
        link: '/admin/parametrage/home-slider',
      }
    ]
  } , */
   
];
